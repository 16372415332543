.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: $color-primary-primary-500 !important;
}
.mat-form-field-underline {
  /*change color of underline*/
  background-color: $color-primary-primary-500 !important;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: $color-text-muted !important;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.01563rem;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: $color-text-muted !important;
}

mat-form-field.input-full-width {
  width: 100%;
}

// Checkbox background color

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $color-primary-primary-500;
}

.mat-menu-panel.dark-theme {
  background: $color-surfaces-dark-surface1;
  padding: 0.5rem;
  button.mat-menu-item {
    background: $color-neutrals-neutrals-800;
    color: white;
    border-radius: 0.3rem;
    height: 3rem;
  }
}

.mat-menu-panel {
  button.mat-menu-item {
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1rem;

    @include font-size(1rem !important);

    span.menu-item-text {
      @include margin-left(0.5rem !important);
    }
  }
}

.dx-trackbar-container.dx-progressbar-container {
  height: 1.3rem;
  border-radius: 1rem;
  background-color: $color-primary-primary-050;
  border: 0.0625rem solid $color-primary-primary-050;

  .dx-trackbar-range.dx-progressbar-range {
    border-radius: 1rem;
    background-color: $color-primary-normal;
    border-color: $color-primary-normal;
  }
}
.small-progress-bar {
  .dx-trackbar-container.dx-progressbar-container {
    height: 0.3rem;
  }
}

//MatOption

.mat-option {
  color: black !important;
  &:hover {
    background: #d9edf4 !important;
  }
}

.mat-table {
  border-radius: 0.25rem;
  background-color: transparent;
  thead {
    background-color: $color-primary-primary-100;
    border-radius: 0.25rem;
    tr.mat-header-row {
      border-radius: 0.25rem;
      .mat-header-cell {
        color: $color-text-black;
        /* Font/OVERLINE */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.75rem; /* 120% */
        letter-spacing: 0.0938rem;
        text-transform: uppercase;

        border-bottom: none;
        &:first-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        &:last-child {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }

        /*         .mat-sort-header-arrow{
          color: $color-text-black;
          .mat-sort-header-stem{
            height: 0.625rem;
            width: 0.125rem;
          }
          .mat-sort-header-indicator{
            height: 0.125rem;
            .mat-sort-header-pointer-left{
              width: 0.375rem;
              height: 0.125rem;
            }
            .mat-sort-header-pointer-right{
              width: 0.375rem;
              height: 0.125rem;
            }
            .mat-sort-header-pointer-middle{
              height: 0.125rem;
              width: 0.125rem;
            }
          }
        } */
      }
    }
  }
  tbody {
    position: relative;
    top: 0.5rem;
    background-color: $surface-1;
    tr.mat-row {
      &:first-child {
        .mat-cell:first-child {
          border-top-left-radius: 0.25rem;
        }
        .mat-cell:last-child {
          border-top-right-radius: 0.25rem;
        }
      }
      &:last-child {
        .mat-cell {
          border-bottom: none;
        }
        .mat-cell:first-child {
          border-bottom-left-radius: 0.25rem;
        }
        .mat-cell:last-child {
          border-bottom-right-radius: 0.25rem;
        }
      }

      &.selected {
        background-color: $color-primary-selected;
      }
    }
  }
}

.material-symbols-outlined {
  font-size: 1.5rem;
}

mat-dialog-container {
  width: 44rem !important;
  min-height: 24rem !important;
  margin-top: 9.5rem;
  padding: 2rem 3rem !important;
  height: auto !important;
  @include padding(2rem 3rem !important);

  @include media('mobile&tablet') {
    margin-top: 0;
  }

  .mat-dialog-title {
    display: flex;
    justify-content: space-between;
    .close-icon {
      width: 2.25rem;
      height: 2.25rem;
      font-size: 2.25rem;
    }
  }
  .buttons-list {
    min-height: 2rem;
    height: 3rem;
    padding: 0;
    margin-bottom: 0;
    justify-content: space-between;
    @include media('mobile&tablet') {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: nowrap;
    }
  }
}

.custom-mat-menu {
  .mat-menu-content {
    padding-bottom: 0 !important;
    .delete {
      color: $color-feedback-error-error;
      &:hover {
        background-color: $color-feedback-error-hover !important;
      }
    }
  }
}

.mat-datepicker-toggle {
  .mat-button-wrapper {
    color: $color-feedback-action-action;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: $color-primary-normal;
  }
}

.mat-button-toggle-group {
  border: 0.0625rem solid $color-primary-normal;
  .mat-button-toggle {
    &.mat-button-toggle-checked {
      background-color: $color-feedback-action-action;
      &.mat-button-toggle-appearance-standard {
        color: $color-neutrals-neutrals-000;
      }
    }
    &.mat-button-toggle-appearance-standard {
      color: $color-feedback-action-action;
    }

    .mat-button-toggle-button {
      height: 3rem;
      .mat-button-toggle-label-content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
mat-slide-toggle.mat-slide-toggle {
  height: 1.5rem;
  line-height: 1.5rem;
  .mat-slide-toggle-bar {
    width: 2.25rem;
    height: 0.875rem;
    border-radius: 0.5rem;
    .mat-slide-toggle-thumb-container {
      width: 1.25rem;
      height: 1.25rem;
      top: -0.1875rem;
      .mat-slide-toggle-thumb {
        height: 1.25rem;
        width: 1.25rem;
      }
      .mat-slide-toggle-ripple {
        top: calc(50% - 1.25rem);
        left: calc(50% - 1.25rem);
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(1rem, 0, 0) !important;
}
