@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @-ms-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

/*
 * — Animations —
 */
@include keyframes(fadein) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include keyframes(flexBottomFormRetract) {
  from {
    max-height: 28rem;
  }
  to {
    max-height: 0;
  }
}

@include keyframes(flexBottomFormExpand) {
  from {
    max-height: 0;
  }
  to {
    max-height: 28rem;
  }
}

@include keyframes(installation-structure-retract) {
  0% {
    margin-left: 0;
  }
  20% {
    margin-left: 0;
  }
  100% {
    margin-left: calc(30rem + 0.75rem);
  }
}

@include keyframes(installation-structure-expand) {
  0% {
    margin-left: calc(30rem + 0.75rem);
  }
  20% {
    margin-left: calc(30rem + 0.75rem);
  }
  100% {
    margin-left: 0;
  }
}

@include keyframes(right-card-retract) {
  0% {
    margin-right: 0;
  }
  20% {
    margin-right: 0;
  }
  100% {
    margin-right: calc(20rem + 0.75rem);
  }
}

@include keyframes(right-card-expand) {
  0% {
    margin-right: calc(20rem + 0.75rem);
  }
  20% {
    margin-right: calc(20rem + 0.75rem);
  }
  100% {
    margin-right: 0;
  }
}

@include keyframes(retract) {
  0% {
    margin-right: 0;
  }
  20% {
    margin-right: 0;
  }
  100% {
    margin-right: calc(17.5rem + 0.75rem);
  }
}

@include keyframes(expand) {
  0% {
    margin-right: calc(17.5rem + 0.75rem);
  }
  20% {
    margin-right: calc(17.5rem + 0.75rem);
  }
  100% {
    margin-right: 0;
  }
}

@include keyframes(openDrawerRight) {
  0% {
    left: -100%;
  }
  100% {
    left: 24px;
  }
}

@include keyframes(closeDrawerLeft) {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@include keyframes(openDrawerLeft) {
  0% {
    right: -100%;
  }
  100% {
    right: 24px;
  }
}

@include keyframes(closeDrawerRight) {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}

@include keyframes(openDrawerUp) {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@include keyframes(closeDrawerBottom) {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}

@include keyframes(flex-in) {
  0% {
    flex: $flex-zero;
  }
  100% {
    flex: 1;
  }
}

@include keyframes(flex-out) {
  0% {
    flex: 1;
  }
  100% {
    flex: $flex-zero;
  }
}

@include keyframes(fadeAndScale) {
  from {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@include keyframes(zoom-in-out-text) {
  0% {
    font-size: 16px;
  }
  5% {
    font-size: 16.5px;
  }
  10% {
    font-size: 17px;
  }
  15% {
    font-size: 17.5px;
  }
  20% {
    font-size: 18px;
  }
  25% {
    font-size: 18.5px;
  }
  30% {
    font-size: 19px;
  }
  35% {
    font-size: 19.5px;
  }
  40% {
    font-size: 20px;
  }
  45% {
    font-size: 20.5px;
  }
  50% {
    font-size: 20.5px;
  }
  55% {
    font-size: 20.5px;
  }
  60% {
    font-size: 20px;
  }
  65% {
    font-size: 19.5px;
  }
  70% {
    font-size: 19px;
  }
  75% {
    font-size: 18.5px;
  }
  80% {
    font-size: 18px;
  }
  85% {
    font-size: 17.5px;
  }
  90% {
    font-size: 17px;
  }
  95% {
    font-size: 16.5px;
  }
  100% {
    font-size: 16px;
  }
}

@include keyframes(shake) {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@include keyframes(kf_shake) {
  0% {
    transform: translate(8px);
  }
  20% {
    transform: translate(-8px);
  }
  40% {
    transform: translate(4px);
  }
  60% {
    transform: translate(-4px);
  }
  80% {
    transform: translate(2px);
  }
  100% {
    transform: translate(0px);
  }
}

@include keyframes(sk-bounce) {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@include keyframes(flicker) {
  0% {
    background-color: $c-highlight;
  }
  33% {
    background-color: white;
  }
  66% {
    background-color: $c-highlight;
  }
  100% {
    background-color: white;
  }
}

@include keyframes(sk-bouncedelay) {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

// keyframe for loading
@include keyframes(sk-scaleout) {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@include keyframes(placeHolderShimmer) {
  0% {
    background-position: -10vw 0;
  }
  100% {
    background-position: 70vw;
  }
}
