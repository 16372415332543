@use '@angular/material' as mat;
@import 'variables';
@import 'animations';

@mixin media($screen) {
  @if $screen == 'mobile&tablet' {
    @media (max-width: 768px) {
      @content;
    }
  }
  @if $screen == 'mobile' {
    @media (max-width: 375px) {
      @content;
    }
  }
  @if $screen == 'tablet' {
    @media (min-width: 376px) and (max-width: 768px) {
      @content;
    }
  }
  @if $screen == 'desktop' {
    @media (min-width: 769px) {
      @content;
    }
  }
  @if $screen == 'widetablet' {
    @media (min-width: 1020px) and (max-width: 1040px) and (max-height: 600px) {
      @content;
    }
  }
  @if $screen == 'talltablet' {
    @media (min-width: 769px) and (max-width: 912px) and (max-height: 1368px) {
      @content;
    }
  }
  @if $screen == 'shortphone' {
    @media (max-width: 375px) and (max-height: 667px) {
      @content;
    }
  }
  @if $screen == 'skinnyphone' {
    @media (max-width: 280px) and (max-height: 653px) {
      @content;
    }
  }
  @if $screen == 'shortscreen' {
    @media (max-height: 600px) {
      @content;
    }
  }
  // 4k or Ultra High Definition (UHD)
  @if $screen == '4k' {
    @media (max-width: 3840px) and (max-height: 2160px) {
      @content;
    }
  }
  // Wide Quad High Definition (WQHD)
  @if $screen == 'wqhd' {
    @media (max-width: 3440px) and (max-height: 1440px) {
      @content;
    }
  }
  // Quad High Definition (QHD)
  @if $screen == 'qhd' {
    @media (max-width: 2560px) and (max-height: 1440px) {
      @content;
    }
  }
  // Wide Ultra Extended Graphics Array (WUXGA)
  @if $screen == 'wuxga' {
    @media (max-width: 1920px) and (max-height: 1200px) {
      @content;
    }
  }
  // Full High Definition (FHD)
  @if $screen == 'fhd' {
    @media (max-width: 1920px) and (max-height: 1080px) {
      @content;
    }
  }
  // High Definition Plus (HD+)
  @if $screen == 'hdPlus' {
    @media (max-width: 1600px) and (max-height: 900px) {
      @content;
    }
  }
  // High Definition (HD)
  @if $screen == 'hd' {
    @media (max-width: 1366px) and (max-height: 768px) {
      @content;
    }
  }
  // Super-eXtended Graphics Array (SXGA)
  @if $screen == 'sxga' {
    @media (max-width: 1280px) and (max-height: 1024px) {
      @content;
    }
  }

  // Tablet Landscape
  @if $screen == 'tabletLandScape' {
    @media (max-width: 1080px) and (min-width: 962px) {
      @content;
    }
  }

  // Mobile Landscape
  @if $screen == 'mobileLandScape' {
    @media (max-width: 915px) and (min-width: 800px) {
      @content;
    }
  }

  // Tablet Portrait
  @if $screen == 'tabletPortrait' {
    @media (max-width: 800px) and (min-width: 768px) {
      @content;
    }
  }
}



@mixin transform-rotate($angle) {
  -webkit-transform: rotate($angle); /* Chrome and other webkit browsers */
  -moz-transform: rotate($angle); /* FF */
  -ms-transform: rotate($angle); /* IE9 */
  -o-transform: rotate($angle); /* Opera */
  transform: rotate($angle); /* W3C compliant browsers */
}

@mixin styles-colors-theme($theme) {
  $primary: map-get($theme, primary);
  $color-primary-light: mat.get-color-from-palette($primary, $palette-lighter);

  input[type='email'],
  input[type='password'],
  input[type='text'],
  input[type='number'],
  textarea {
    &:focus {
      border-color: $color-primary-light;
    }
  }
}

@mixin moap-rotate($angle) {
  -webkit-transform: rotate($angle); /* Chrome and other webkit browsers */
  -moz-transform: rotate($angle); /* FF */
  -ms-transform: rotate($angle); /* IE9 */
  -o-transform: rotate($angle); /* Opera */
  transform: rotate($angle); /* W3C compliant browsers */
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-animation: $transition;
  transition: $transition;
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin md-icon-size($size: 1.5rem) {
  width: $size;
  height: $size;
  font-size: $size;
  line-height: $size;
}

@mixin moap-rotate($angle) {
  -webkit-transform: rotate($angle); /* Chrome and other webkit browsers */
  -moz-transform: rotate($angle); /* FF */
  -ms-transform: rotate($angle); /* IE9 */
  -o-transform: rotate($angle); /* Opera */
  transform: rotate($angle); /* W3C compliant browsers */
}

@mixin moap-scale($x, $y) {
  -webkit-transform: scale($x, $y); /* Chrome and other webkit browsers */
  -moz-transform: scale($x, $y); /* FF */
  -ms-transform: scale($x, $y); /* IE9 */
  -o-transform: scale($x, $y); /* Opera */
  transform: scale($x, $y); /* W3C compliant browsers */
}

@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ', '};
    }
  }
  -webkit-animation: $animations;
  -moz-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}

@mixin no-selection() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
 * Table columns
**/
@mixin mat-table-columns($columns) {
  .mat-column- {
    @each $colName, $props in $columns {
      $width: map-get($props, 'width');

      &#{$colName} {
        flex: 0 0 $width;
        min-width: $width;
        max-width: $width;

        @if map-has-key($props, 'color') {
          color: map-get($props, 'color');
        }
      }
    }
  }
}
