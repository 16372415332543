@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import 'material-symbols/outlined';
@import '~ngx-toastr/toastr';
@import '~rfs/scss';
@import '@angular/cdk/overlay-prebuilt.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* You can add global styles to this file, and also import other style files */
// @import 'styles/fonts';
@import 'styles/material.color';
@import 'styles/material.typography';
@import 'styles/mixins'; // <= here too [ @import 'variables'; & @import 'animations'; ]
@import 'styles/variables';
@import 'styles/globals';
@import 'styles/reset';
// @import 'styles/old-custom-global';
