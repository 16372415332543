@use '@angular/material' as mat;
@import 'colors';

// Header color
$header-color: $main-color;

$min-width: 1150px;
$min-height: 640px;

// Flex
$flex-zero: 0.0001;

// Font Weights
$font-weight-light: 300; //DEPRECATED
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extra-bold: 900;

// Fonts
$font-family: 'Roboto';
$font-large: $font-weight-medium 1rem $font-family;
$font-medium: $font-weight-medium 0.875rem $font-family;
$font-regular: $font-weight-regular 0.75rem $font-family;
$font-label: $font-weight-medium 0.75rem $font-family;

// Shadows
$box-shadow-small: 0 0 1rem 0 rgba(0, 0, 0, 0.375);
$box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.125);
$box-shadow-right: 1.25rem 0 1.25rem -1.25rem rgba(0, 0, 0, 0.125);
$box-bottom-shadow: 0 1.25rem 1.25rem -1.25rem rgba(0, 0, 0, 0.125);
$box-shadow-large: 0 0 3rem 0 rgba(0, 0, 0, 0.375);
$box-shadow-survey-card-box: 0.0625rem 0.0625rem 0.6875rem 0.5rem rgba(235, 236, 241, 1);

// Main colors
$app-white: #fff;
$app-white-trans: #ffffff33;
$app-black: #313751; // Color used for text and icons.
$app-black-trans: #00003333;
$grey-1: #5a5b5c;
$grey-1-trans: #9295a933;
$grey-2: #dae0e9; // Color used for lines and fields.
$grey-2-trans: #dae0e933;
$grey-3: #f8f9fb; // Color used for backgrounds.
$grey-3-trans: #f8f9fb33;

$black-trans: #0000000a;

// Palette colors
$palette-lighter: 100;
$palette-main: 500;
$palette-darker: 900;

// Specific Colors
$c-highlight: $grey-2;
$c-text: $app-black;
$c-ctclite: #a6dbc1;
$c-ctcplus: #ff9480;
$c-background: $grey-3;
$c-background-trans: $grey-3-trans;
$c-border: $grey-2;
$c-border-trans: $grey-2-trans;
$c-disabled: $grey-2;
$c-error: #87000b;
$c-success: #4caf50;
$c-warning: #ffa500;
$c-primary: #0971ce;
$c-icons: #8a9ca0;
$c-cyan: #00e0fc;
$c-cloud: #cad8f3;

$primary-nav-bar-background: rgb(1, 181, 201);
$primary-nav-bar-linear-gradient: linear-gradient(
  90deg,
  rgba(1, 181, 201, 1) 0%,
  rgba(1, 132, 166, 1) 100%
);
$buttons-nav-bar-background: rgb(0, 158, 199);
$buttons-nav-bar-linear-gradient: linear-gradient(
  90deg,
  rgba(0, 158, 199, 1) 0%,
  rgba(1, 181, 201, 1) 100%
);
$blue-title-bar-background: #10cedd;
$yellow-background: #fbd532;
$lemon-background: #d3d95f;

// BORDER
$border-radius-medium: 0.25rem;
$border-radius: 0.5rem;
$border-light: 0.0625rem solid $c-border;

// Responsive Font Sizes
$_10px: 0.625rem;
$_11px: 0.6875rem;
$_12px: 0.75rem;
$_13px: 0.8125rem;
$_14px: 0.875rem;
$_15px: 0.9375rem;
$_16px: 1rem;
$_18px: 1.125rem;
$_20px: 1.25rem;
$_22px: 1.375rem;
$_23px: 1.4375rem;
$_24px: 1.5rem;

$surface-1: #fcfcfc;

$color-neutrals-almost-black: #474747;
$color-neutrals-mid-grey: rgba(71, 71, 71, 0.65);
$color-primary-primary-500: #41a5c7;
$color-text-muted: #999999;
$color-primary-primary-800: #276377;
$color-brand-blue: #009eec;
$color-neutrals-neutrals-000: #fffffe;
$color-surfaces-dark-surface1: #1c1b1f;
$color-neutrals-neutrals-800: #404040;
$color-neutrals-neutrals-100: #E8E8E8;
$color-feedback-action-action-hover:#0148A1;
$color-primary-primary-050: #D9EDF4;
$color-primary-primary-100: #A0D2E3;
$color-primary-dark:#2E748C;
$color-primary-darkest: #215364;
$color-primary-normal: #41A5C7;
$color-wireframe-neutrals-neutral-80: #404040;
$color-wireframe-neutrals-neutral-40: #999999;
$color-gradient-blue-mid:linear-gradient(269deg, #06427A 0%, #0A71CE 50%, #387FC1 100%);
$color-surface-surface2: #F3F3F3;
$color-text-black: #101010;
$color-primary-selected:#D9EDF4;
$color-feedback-error-hover: #F9D2CE;
$color-feedback-error-error:#E0200C;
$color-feedback-error-error-on-hover:#5A0D05;
$color-feedback-action-action: #0A71CE;
$color-feedback-warning: #E9B501;




