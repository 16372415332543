@import 'animations';

html,
body {
  font-family: Roboto;
  height: 100vh;
  height: 100dvh;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
  letter-spacing: 0.03125rem;

  // IMPROVEMENT PROPOSAL
  // font-size: 0.875rem;
  // font-size: clamp(0.875rem, 1vw, 2rem);

  // USUAL BEHAVIOR
  font-size: 0.833vw !important; // view-port 1920px => 16px
  @include media('mobile&tablet') {
    font-size: 4.103vw !important; // view-port 390px => 16px
  }
}
.no-margin-dialog-panel {
  .mat-dialog-container {
    margin-top: 0 !important;
  }
}

.main-component-container {
  height: calc(100% - 6.7rem);
  @include padding(1rem);
  background-color: $color-surface-surface2;

  .filters-container {
    @include rfs(3rem, height);
    @include margin-bottom(1rem);
    @include padding(0rem);

    .filters-button {
      @include padding(0.5rem 1.38rem 0.5rem 1rem);
      height: 3rem;
      background-color: $surface-1;

      span.mat-button-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        height: 100%;
      }
    }
  }

  .list-container {
    @include rfs(6rem, min-height);
  }

  .bottom-form-container {
    &.drawer-bottom-shown {
      animation: flexBottomFormExpand 0.4s ease forwards;
    }

    &.drawer-bottom-closing {
      animation: flexBottomFormRetract 0.4s ease forwards;
    }
  }
}

.head-line-2 {
  @include font-size(3.75rem);
  font-style: normal;
  font-weight: 300;
  @include rfs(4.5rem, line-height);
  /* 120% */
  letter-spacing: -0.03125rem;
}

.head-line-3 {
  // @include font-size(2.125rem);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  @include rfs(2.5rem, line-height);
  /* 117.647% */
  letter-spacing: 0.01563rem;
}

.head-line-4 {
  @include font-size(2.125rem);
  font-style: normal;
  font-weight: 400;
  @include rfs(2.5rem, line-height);
  /* 117.647% */
  letter-spacing: 0.01563rem;
}

.head-line-5 {
  @include font-size(1.5rem);
  font-style: normal;
  font-weight: 400;
  @include rfs(2rem, line-height);
  /* 133.333% */
  letter-spacing: 0.01563rem;
}

.overline {
  @include font-size(0.625rem);
  font-style: normal;
  font-weight: 400;
  @include rfs(0.75rem, line-height);
  /* 120% */
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
}

.m3-title-small {
  /* M3/title/small */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00625rem;
}

.m3-label-large {
  /* M3/label/large */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00625rem;
}

.m3-label-small {
  /* M3/label/small */
  font-family: Roboto;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  /* 145.455% */
  letter-spacing: 0.03125rem;
}

.m3-display-large {
  font-family: Roboto;
  font-size: 3.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4rem; /* 112.281% */
}

.font-body-1 {
  /* Font/Body 1 */
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.0313rem;
}

.font-body-2 {
  /* Font/Body 2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  /* 142.857% */
  letter-spacing: 0.01563rem;
}

.font-subtitle-2 {
  /* Font/Subtitle 2 */
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  /* 142.857% */
  letter-spacing: 0.00625rem;
}

.font-headline-2 {
  /* Font/Headline 2 */
  font-family: Roboto;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 4.5rem; /* 120% */
  letter-spacing: -0.03125rem;
}

.font-headline-4 {
  /* Font/Headline 4 */
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem; /* 117.647% */
  letter-spacing: 0.0156rem;
}

.font-headline-5 {
  /* Font/Headline 5 */
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 133.333% */
}

.font-overline {
  font-family: Roboto;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem; /* 120% */
  letter-spacing: 0.0938rem;
  text-transform: uppercase;
}

.font-caption {
  /* Font/Caption */
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.025rem;
}

// colors

.color-wireframe-neutrals-neutral-40 {
  color: $color-wireframe-neutrals-neutral-40;
}
.color-text {
  &-muted {
    color: $color-text-muted;
  }
  &-black {
    color: $color-text-black;
  }
}

.color-feedback-error-error {
  color: $color-feedback-error-error;
}
.color-feedback-warning {
  color: $color-feedback-warning;
}
.color-feedback-action-action {
  color: $color-feedback-action-action;
}

.color-primary-normal {
  color: $color-primary-normal;
}

.color-primary-dark {
  color: $color-primary-dark;
}

.color-neutrals-almost-black {
  color: $color-neutrals-almost-black;
}

.color-primary-primary-800 {
  color: $color-primary-primary-800;
}

.c {
  &-ctclite {
    color: $c-ctclite !important;
  }

  &-ctcplus {
    color: $c-ctcplus !important;
  }

  &-white {
    color: $app-white !important;
  }

  &-error {
    color: $c-error !important;
  }

  &-error-2 {
    color: $color-feedback-error-error !important;
  }

  &-success {
    color: $c-success !important;
  }

  &-warning {
    color: $c-warning !important;
  }
}

.b {
  &-error {
    background-color: $c-error !important;
  }

  &-success {
    background-color: $c-success !important;
  }

  &-warning {
    background-color: $c-warning !important;
  }
}

.custom-tooltips {
  background-color: $color-feedback-action-action !important;
  color: $color-neutrals-neutrals-000 !important;
  font-size: 12px !important;
}

.mat-form-field-container {
  display: flex;
  flex-direction: column;
  span.fake-label {
    color: $color-primary-primary-800;
  }

  &.mat-checkbox-field-container {
    .mat-form-field-subscript-wrapper {
      margin-top: 2.2em;
    }
  }
}

.custom-title-close {
  .close-container {
    .close-icon {
      font-size: 2.2rem;
    }
  }
}

.elevation-3 {
  /* Elevations/Elevation3 */
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.list-icon-button {
  &:hover {
    background-color: $color-primary-primary-100;
  }
}

.button {
  text-transform: uppercase;

  &:hover {
    background-color: $color-feedback-action-action-hover;
  }

  &-primary {
    &:hover {
      background-color: $color-feedback-action-action-hover;
    }
    /*     .hoverIcon{
        opacity: 0;
        transition: opacity 0.5s;
      } */
    &.mat-warn {
      &:hover {
        background-color: $color-feedback-error-hover;
        .mat-button-wrapper {
          color: $color-feedback-error-error-on-hover;
        }
      }
    }
  }

  &-outline {
    border: 1px solid $color-feedback-action-action !important;
    text-transform: uppercase;
    box-sizing: border-box !important;

    &:hover {
      background-color: #d9edf4 !important;
      border: 1px solid transparent !important;
    }
  }

  &-primary,
  &-outline {
    .icon {
      font-size: 1.2rem;
      margin-right: 0.5rem;
      line-height: 0;
    }
  }
  &-fixed-height {
    height: 3rem;
    display: flex !important;
    align-items: center;
    @include media('mobile&tablet') {
      height: 2rem;
      font-size: 0.5rem !important;
    }
  }
}

.button-hoverIcon {
  .hoverIcon {
    display: none;
    font-size: 1.2rem;
  }

  &:hover {
    /*     .hoverIcon{
      display: inline-block;
      opacity: 1;
    } */
    .btn-text {
      margin-left: 0.5rem;
    }
  }
}

.font-button {
  @include font-size(0.875rem);
  font-style: normal;
  font-weight: 500;
  @include rfs(1.5rem, line-height);
  /* 171.429% */
  letter-spacing: 0.07813rem;
  text-transform: uppercase;
}

.surface-1 {
  background-color: #fcfcfc !important;
}

mat-card.row-details {
  @include padding(1rem);

  mat-card-title {
    @include margin-bottom(1.5rem);
  }

  mat-card-content {
    .detail-field:not(:first-child),
    .detail-button:not(:first-child) {
      @include margin-top(1rem);
    }

    .detail-button {
      @include padding(0.5rem 1.12rem 0.5rem 0.75rem);

      span.mat-button-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}

.hidden {
  display: none !important;
}

// text
.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.dots {
  white-space: nowrap;
  /* prevents text deformation */
  overflow: hidden;
  text-overflow: ellipsis;

  &.dots-multiline {
    white-space: normal;
  }
}

.dots-multiline-webkit {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

// Angular Material tables
.ds-mat-table {
  width: 100%;

  thead {
    top: 0;
    position: sticky;
    z-index: 10;

    tr.mat-header-row {
      height: 2.5rem;

      th {
        background: transparent;

        &.mat-sort-header {
          .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
            opacity: 0.54 !important;
            transform: translateY(0px) !important;
          }
        }

        &.mat-header-cell:first-of-type {
          padding-left: 1rem !important;
        }

        &.mat-header-cell:last-of-type {
          padding-right: 1rem !important;
        }

        &.mat-header-cell:not(:last-child) {
          padding-right: 0.88rem;
        }
      }
    }
  }

  tbody {
    tr.mat-row {
      &:hover .mat-cell {
        background-color: $grey-1-trans;
        transition: background-color 0.5s;
      }
      &.highlight {
        @include animation('flicker 3s linear');
      }

      &.disabled > td > * {
        opacity: 0.4 !important;
      }

      td {
        &.mat-cell:first-of-type {
          padding-left: 1rem !important;
        }

        &.mat-cell:last-of-type {
          padding-right: 1rem !important;
        }

        &.mat-cell:not(:last-child) {
          padding-right: 0.88rem;
        }
      }
    }
  }
}

// flex layout
.d {
  &-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  &-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  &-flex,
  &-inline-flex {
    &.justify-content {
      &-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
      }

      &-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
      }

      &-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
      }

      &-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
      }

      &-around {
        //-ms-flex-pack: distribute !important;
        justify-content: space-around !important;
      }
    }

    &.align-content {
      &-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
      }

      &-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
      }

      &-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
      }

      &-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
      }

      &-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
      }

      &-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
      }
    }

    &.align-items {
      &-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
      }

      &-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
      }

      &-center {
        -ms-flex-align: center !important;
        align-items: center !important;
      }

      &-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
      }

      &-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
      }
    }

    &.flex {
      &-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;

        &-reverse {
          -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
        }
      }

      &-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;

        &-reverse {
          -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
        }
      }

      &-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
      }

      &-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;

        &-reverse {
          -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
        }
      }

      &-gap {
        @for $i from 0 through 10 {
          &-#{$i}:not(.flex-column):not(.flex-reverse-column) {
            > *:not(:last-of-type) {
              @include margin-right(#{$i}rem);
            }
          }

          &-#{$i}.flex-column,
          &-#{$i}.flex-reverse-column {
            > *:not(:last-of-type) {
              @include margin-bottom(#{$i}rem);
            }
          }
        }
      }
    }

    .align-self {
      &-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
      }

      &-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
      }

      &-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
      }

      &-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
      }

      &-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
      }
    }

    &.flex,
    .flex {
      &-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;

        &-0 {
          -ms-flex: 1 1 0 !important;
          flex: 1 1 0 !important;
        }
      }

      &-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
      }

      &-grow-2 {
        -ms-flex-positive: 2 !important;
        flex-grow: 2 !important;
      }

      &-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
      }

      @for $i from 0 through 20 {
        &-#{$i*5} {
          @if $i ==0 {
            flex: $i;
          } @else {
            flex: #{$i * 5%};
          }
        }
      }

      &-width {
        @for $i from 0 through 20 {
          &-#{$i*5} {
            @if $i ==0 {
              flex: 1 1 100%;
              max-width: $i;
            } @else {
              flex: 1 1 100%;
              max-width: #{$i * 5%};
            }
          }

          &-#{$i} {
            &-rem {
              flex: 1 1 #{$i}rem;
              box-sizing: border-box;
              max-width: #{$i}rem;
              min-width: #{$i}rem;
            }
          }
        }
      }
    }
  }
}

.margin-auto {
  &-top {
    margin-top: auto !important;
  }

  &-bottom {
    margin-bottom: auto !important;
  }

  &-left {
    margin-left: auto !important;
  }

  &-right {
    margin-right: auto !important;
  }
}

.cursor {
  cursor: default !important;

  &-none {
    cursor: none !important;
  }

  &-pointer {
    cursor: pointer !important;
  }

  &-loading {
    cursor: wait !important;
  }

  &-select {
    cursor: text !important;
  }

  &-not {
    cursor: not-allowed !important;
  }

  &-grabbing {
    cursor: grab !important;
    cursor: -webkit-grab !important;

    &:active {
      cursor: grabbing !important;
      cursor: -webkit-grabbing !important;
    }
  }

  &-grabbed {
    cursor: grabbing !important;
    cursor: -webkit-grabbing !important;
  }

  &-not-allowed {
    cursor: not-allowed !important;
  }
}

.visible {
  &-desktop,
  &-mobile {
    display: none;
    opacity: 0; // when "display" is stepped on by another "display"
    //&.mat-card {
    //  display: none;
    //}
  }

  &-desktop {
    @media (min-width: 769px) {
      display: initial;
      opacity: 1; //when "display" is stepped on by another "display"
      @at-root {
        td#{&},
        th#{&} {
          display: table-cell;
        }
      }
    }
  }
  &-mobile {
    @media (max-width: 768px) {
      opacity: 1; //when "display" is stepped on by another "display"
      display: initial;
    }
  }
}

.mat-form-field.custom-select {
  .mat-form-field-wrapper {
    max-width: 17rem;
    .mat-form-field-flex {
      background-color: $surface-1;
      border-radius: 0.25rem;
      border-bottom: 0.0625rem solid $color-primary-primary-500;
      padding: 0 0.5rem;
    }
    .mat-form-field-underline {
      height: 0px;
    }
  }
}

.agree-to-tos-dialog-panel {
  justify-content: center;
  align-items: normal;
  max-width: unset !important;
  width: 75%;
  height: 75%;

  mat-dialog-container {
    width: 100% !important;
    min-height: 24rem !important;
    padding: 2rem 3rem !important;
    height: auto !important;
    margin-top: unset !important;
  }
}
